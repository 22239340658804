<template>
  <div class="step-content">
    <div class="d-block">
      <div class="title">{{ $t('questions.step_6.title') }}</div>
      <SelectBox @update-val="updateVal"
                 type="radio"
                 ref="selectBox"
                 :check-data="$t('questions.step_6.checkbox')"/>
    </div>
    <div class="d-flex justify-content-center">
      <RestartBtn/>
      <router-link class="btn" to="7" :class="{disabled: !selected.length}">{{
          $t('questions.step_6.btnText')
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
import SelectBox from "../components/SelectBox";
import {updateVal} from "../mixins/update-val";

export default {
  name: 'Step_6',
  mixins: [updateVal],
  components: {
    SelectBox
  },
}
</script>
